import React, { useState } from 'react';
import axios from 'axios';

const API_BASE_URL = 'https://api.opsecguardian.com';

function AdminDashboard() {
  const [users, setUsers] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editUpdates, setEditUpdates] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const getToken = () => localStorage.getItem('token');

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(parseInt(timestamp) * 1000).toLocaleString();
  };

  const parseUserData = (userData) => {
    if (!userData) return {};
    
    // If the data is already in regular JSON format (not DynamoDB), return it with defaults
    if (userData.email && typeof userData.email === 'string') {
      return {
        ...userData,
        role: userData.role || 'user',
        subscription_status: userData.subscription_status || 'inactive',
        scans_limit: userData.scans_limit || '0',
        scans_used_this_month: userData.scans_used_this_month || '0',
        openvas_setup: userData.openvas_setup || false,
        stripe_customer_id: userData.stripe_customer_id || null,
        subscription_tier: userData.subscription_tier || null,
      };
    }
    
    // Parse DynamoDB formatted data into a clean object
    const cleanData = {};
    Object.entries(userData).forEach(([key, value]) => {
      // Skip PK and SK
      if (key === 'PK' || key === 'SK') return;
      if (!value) return; // Skip null/undefined values
      
      // Extract the actual value based on DynamoDB type
      if (value.S !== undefined) cleanData[key] = value.S;
      else if (value.N !== undefined) cleanData[key] = value.N;
      else if (value.BOOL !== undefined) cleanData[key] = value.BOOL;
      else if (value.NULL === true) cleanData[key] = null;
    });

    // Set default values for required fields
    cleanData.role = cleanData.role || 'user';
    cleanData.subscription_status = cleanData.subscription_status || 'inactive';
    cleanData.scans_limit = cleanData.scans_limit || '0';
    cleanData.scans_used_this_month = cleanData.scans_used_this_month || '0';
    cleanData.openvas_setup = cleanData.openvas_setup || false;
    cleanData.stripe_customer_id = cleanData.stripe_customer_id || null;
    cleanData.subscription_tier = cleanData.subscription_tier || null;
    
    return cleanData;
  };

  const formatValue = (value) => {
    if (value === null || value === undefined) return 'N/A';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    return value;
  };

  const listUsers = async () => {
    const token = getToken();
    if (!token) {
      alert('Please log in as an admin.');
      return;
    }
    try {
      console.log('Fetching users...');
      const response = await axios.get(`${API_BASE_URL}/admin/users`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('API Response:', response.data);
      
      // Check if response.data is an array or if it's nested
      const usersArray = Array.isArray(response.data) ? response.data : 
                        response.data.users ? response.data.users :
                        response.data.Items ? response.data.Items : [];
      
      console.log('Processed users array:', usersArray);
      setUsers(usersArray);
      setShowEditForm(false);
      setShowDeleteForm(false);
      setSelectedUser(null);
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      alert('Error fetching users: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowEditForm(true);
    setShowDeleteForm(false);
    setEditUpdates(JSON.stringify({
      role: parseUserData(user).role,
      subscription_status: parseUserData(user).subscription_status,
      subscription_tier: parseUserData(user).subscription_tier,
      scans_limit: parseUserData(user).scans_limit,
      openvas_setup: parseUserData(user).openvas_setup
    }, null, 2));
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setShowDeleteForm(true);
    setShowEditForm(false);
  };

  const submitEdit = async () => {
    const token = getToken();
    if (!token || !selectedUser || !editUpdates) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      await axios.put(
        `${API_BASE_URL}/admin/users`,
        { 
          email: parseUserData(selectedUser).email, 
          updates: JSON.parse(editUpdates)
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('User updated successfully');
      listUsers(); // Refresh the list
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Error updating user: ' + (error.response?.data?.message || error.message));
    }
  };

  const submitDelete = async () => {
    const token = getToken();
    if (!token || !selectedUser) {
      alert('No user selected.');
      return;
    }

    if (!window.confirm(`Are you sure you want to delete user ${parseUserData(selectedUser).email}?`)) {
      return;
    }

    try {
      await axios.delete(`${API_BASE_URL}/admin/users`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { email: parseUserData(selectedUser).email }
      });
      alert('User deleted successfully');
      listUsers(); // Refresh the list
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Error deleting user: ' + (error.response?.data?.message || error.message));
    }
  };

  const filteredUsers = users.filter(user => {
    const userData = parseUserData(user);
    return userData.email && userData.email.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-4">
          <button
            onClick={listUsers}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow transition-colors"
          >
            Refresh Users
          </button>
        </div>
        <div className="flex-1 max-w-md ml-4">
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border rounded-lg"
          />
        </div>
      </div>

      {/* Users List */}
      {users.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscription</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Scans</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">OpenVAS</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Updated</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredUsers.map((rawUser) => {
                const user = parseUserData(rawUser);
                return (
                  <tr key={user.email} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div>
                        <div>{user.email}</div>
                        {user.stripe_customer_id && (
                          <div className="text-xs text-gray-500">
                            Stripe ID: {user.stripe_customer_id}
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.role === 'admin' ? 'bg-purple-100 text-purple-800' : 'bg-green-100 text-green-800'
                      }`}>
                        {user.role}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div>
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          user.subscription_status === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                        }`}>
                          {user.subscription_status}
                        </span>
                        {user.subscription_tier && (
                          <span className="ml-2 text-gray-500">({user.subscription_tier})</span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex flex-col">
                        <span>{user.scans_used_this_month} / {user.scans_limit}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.openvas_setup ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                      }`}>
                        {user.openvas_setup ? 'Setup' : 'Not Setup'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(user.created_at)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(user.updated_at)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        onClick={() => handleEditUser(rawUser)}
                        className="text-indigo-600 hover:text-indigo-900 mr-4"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteUser(rawUser)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* Edit Form */}
      {showEditForm && selectedUser && (
        <div className="mt-6 p-6 bg-gray-50 rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Edit User: {parseUserData(selectedUser).email}</h3>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Role</label>
                <select
                  value={JSON.parse(editUpdates).role || ''}
                  onChange={(e) => {
                    const updates = JSON.parse(editUpdates);
                    setEditUpdates(JSON.stringify({ ...updates, role: e.target.value }, null, 2));
                  }}
                  className="w-full p-2 border rounded-lg"
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Subscription Status</label>
                <select
                  value={JSON.parse(editUpdates).subscription_status || ''}
                  onChange={(e) => {
                    const updates = JSON.parse(editUpdates);
                    setEditUpdates(JSON.stringify({ ...updates, subscription_status: e.target.value }, null, 2));
                  }}
                  className="w-full p-2 border rounded-lg"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Subscription Tier</label>
                <select
                  value={JSON.parse(editUpdates).subscription_tier || ''}
                  onChange={(e) => {
                    const updates = JSON.parse(editUpdates);
                    setEditUpdates(JSON.stringify({ ...updates, subscription_tier: e.target.value || null }, null, 2));
                  }}
                  className="w-full p-2 border rounded-lg"
                >
                  <option value="">None</option>
                  <option value="basic">Basic</option>
                  <option value="pro">Pro</option>
                  <option value="enterprise">Enterprise</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Scans Limit</label>
                <input
                  type="number"
                  value={JSON.parse(editUpdates).scans_limit || 0}
                  onChange={(e) => {
                    const updates = JSON.parse(editUpdates);
                    setEditUpdates(JSON.stringify({ ...updates, scans_limit: e.target.value }, null, 2));
                  }}
                  className="w-full p-2 border rounded-lg"
                  min="0"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">OpenVAS Setup</label>
                <select
                  value={JSON.parse(editUpdates).openvas_setup?.toString() || 'false'}
                  onChange={(e) => {
                    const updates = JSON.parse(editUpdates);
                    setEditUpdates(JSON.stringify({ ...updates, openvas_setup: e.target.value === 'true' }, null, 2));
                  }}
                  className="w-full p-2 border rounded-lg"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Raw JSON (Advanced)
              </label>
              <textarea
                value={editUpdates}
                onChange={(e) => setEditUpdates(e.target.value)}
                className="w-full p-2 border rounded-lg font-mono text-sm"
                rows="8"
              />
              <p className="mt-1 text-sm text-gray-500">
                Advanced: Edit the JSON directly to modify additional fields
              </p>
            </div>
            <div className="flex gap-4">
              <button
                onClick={submitEdit}
                className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded shadow transition-colors"
              >
                Save Changes
              </button>
              <button
                onClick={() => setShowEditForm(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded shadow transition-colors"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation */}
      {showDeleteForm && selectedUser && (
        <div className="mt-6 p-6 bg-red-50 rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Delete User: {parseUserData(selectedUser).email}</h3>
          <p className="text-gray-700 mb-4">
            Are you sure you want to delete this user? This action cannot be undone.
          </p>
          <div className="flex gap-4">
            <button
              onClick={submitDelete}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded shadow transition-colors"
            >
              Confirm Delete
            </button>
            <button
              onClick={() => setShowDeleteForm(false)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded shadow transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
