import React, { useState } from 'react';

function SupportModal({ user }) {
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const closeModal = () => setIsVisible(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulate form submission or integrate with a backend
    console.log('Support request submitted:', { email: user?.email, message });
    setMessage('');
    closeModal();
  };

  return (
    <>
      {/* Button to Open Support Modal */}
      {user && (
        <button
          onClick={() => setIsVisible(true)}
          className="text-gray-300 hover:text-white px-3 py-2"
        >
          Support
        </button>
      )}

      {/* Support Modal */}
      {isVisible && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
          <div className="relative p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <div className="flex justify-between items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Support Request
                </h3>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="mt-2">
                <form onSubmit={handleSubmit}>
                  {/* Pre-filled Email Field (Hidden) */}
                  <input
                    type="hidden"
                    name="user_email"
                    value={user?.email || ''}
                  />
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Message
                    </label>
                    <textarea
                      name="message"
                      rows="4"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    ></textarea>
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Send Support Request
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SupportModal;
