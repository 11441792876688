import React from 'react';

const Filters = ({ filters, onFilterChange, onApplyFilters, onClearFilters }) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onFilterChange({ [name]: value }); // Update the filters state in the parent
  };

  return (
    <div className="filters mb-4 p-4 bg-white rounded shadow">
      <h2 className="text-xl font-bold mb-4">Filters</h2>
      <div className="grid grid-cols-3 gap-4">
        {/* Filter by Target URL */}
        <div>
          <label className="block text-gray-700 mb-2">Filter by Target URL:</label>
          <input
            type="text"
            name="targetUrl"
            value={filters.targetUrl || ''}
            onChange={handleInputChange}
            placeholder="Enter Target URL"
            className="border p-2 w-full rounded"
          />
        </div>

        {/* Start Date */}
        <div>
          <label className="block text-gray-700 mb-2">Start Date:</label>
          <input
            type="date"
            name="startDate"
            value={filters.startDate || ''}
            onChange={handleInputChange}
            className="border p-2 w-full rounded"
          />
        </div>

        {/* End Date */}
        <div>
          <label className="block text-gray-700 mb-2">End Date:</label>
          <input
            type="date"
            name="endDate"
            value={filters.endDate || ''}
            onChange={handleInputChange}
            className="border p-2 w-full rounded"
          />
        </div>

        {/* Filter by Scan Type */}
        <div>
          <label className="block text-gray-700 mb-2">Filter by Scan Type:</label>
          <select
            name="scanType"
            value={filters.scanType || ''}
            onChange={handleInputChange}
            className="border p-2 w-full rounded"
          >
            <option value="">All</option>
            <option value="immediate">Immediate</option>
            <option value="recurring">Recurring</option>
          </select>
        </div>

        {/* Filter by Scan Status */}
        <div>
          <label className="block text-gray-700 mb-2">Filter by Scan Status:</label>
          <select
            name="status"
            value={filters.status || ''}
            onChange={handleInputChange}
            className="border p-2 w-full rounded"
          >
            <option value="">All</option>
            <option value="running">Running</option>
            <option value="completed">Completed</option>
            <option value="on_hold">On Hold</option>
            <option value="failed">Failed</option>
          </select>
        </div>
      </div>

      {/* Buttons */}
      <div className="mt-4 flex gap-4">
        <button
          onClick={onApplyFilters}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Apply Filters
        </button>
        <button
          onClick={onClearFilters}
          className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400"
        >
          Clear Filters
        </button>
      </div>
    </div>
  );
};

export default Filters;
