import React from 'react';

function ComplianceGrid() {
  const standards = [
    {
      title: 'PCI DSS',
      description: 'Payment Data Security',
      color: 'bg-red-600',
      icon: 'fas fa-credit-card',
    },
    {
      title: 'HIPAA',
      description: 'PHI Protection',
      color: 'bg-blue-600',
      icon: 'fas fa-hospital',
    },
    {
      title: 'SOC 2',
      description: 'Security Controls',
      color: 'bg-green-600',
      icon: 'fas fa-shield-alt',
    },
    {
      title: 'ISO 27001',
      description: 'ISMS Framework',
      color: 'bg-purple-600',
      icon: 'fas fa-globe',
    },
    {
      title: 'GDPR',
      description: 'Data Protection',
      color: 'bg-yellow-600',
      icon: 'fas fa-user-shield',
    },
    {
      title: 'NIST',
      description: 'Framework Integration',
      color: 'bg-indigo-600',
      icon: 'fas fa-check-double',
    },
  ];

  return (
    <div id="compliance" className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Complete Compliance Coverage
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            Meet and maintain compliance across multiple standards with our
            comprehensive security solutions
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
          {standards.map((standard, index) => (
            <div
              key={index}
              className="relative bg-white p-6 rounded-lg shadow-lg border border-gray-200"
            >
              <div className="absolute -top-4 left-4">
                <span
                  className={`inline-flex items-center justify-center p-3 ${standard.color} rounded-md shadow-lg`}
                >
                  <i className={`${standard.icon} text-white text-xl`}></i>
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-medium text-gray-900">
                  {standard.title}
                </h3>
                <p className="mt-2 text-base text-gray-500">
                  {standard.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ComplianceGrid;

