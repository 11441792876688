import axios from 'axios';

// Base URL for the main API that includes auth endpoints
const AUTH_API_BASE_URL = 'https://api.opsecguardian.com';

// Create Axios instance for Authentication API
const authApi = axios.create({
  baseURL: AUTH_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add token to requests (if logged in)
authApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Handle response errors globally
authApi.interceptors.response.use(
  (response) => {
    // If the response has a body property that's a string, parse it
    if (response.data && typeof response.data.body === 'string') {
      try {
        response.data = JSON.parse(response.data.body);
      } catch (error) {
        console.error('Error parsing response body:', error);
      }
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors (e.g., logout)
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default authApi;
