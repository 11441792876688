import React from 'react';

function FeaturesSection() {
  const features = [
    {
      title: 'Vulnerability Scanning',
      description: 'Automated scanning and detection of security vulnerabilities',
      icon: 'fas fa-search',
    },
    {
      title: 'Continuous Monitoring',
      description: '24/7 security monitoring and threat detection',
      icon: 'fas fa-shield-alt',
    },
    {
      title: 'Compliance Reporting',
      description: 'Automated compliance reports and documentation',
      icon: 'fas fa-file-alt',
    },
  ];

  return (
    <div id="features" className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
            Features
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Comprehensive Security Solutions
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <i className={`${feature.icon} text-blue-600 text-2xl`}></i>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    {feature.title}
                  </h3>
                  <p className="mt-2 text-base text-gray-500">
                    {feature.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturesSection;
