import axios from 'axios';

const SCAN_API_BASE_URL = 'https://3y1qi5372i.execute-api.us-east-1.amazonaws.com/Prod';

const scanApi = axios.create({
  baseURL: SCAN_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add Authorization header if token exists
scanApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Handle response errors globally
scanApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors (e.g., logout)
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login'; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default scanApi;
