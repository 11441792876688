import React from 'react';
import ReactDOM from 'react-dom/client'; // React 18 uses createRoot for rendering
import './index.css'; // Custom CSS (including Tailwind if configured)
import App from './App'; // Main App component
import reportWebVitals from './reportWebVitals'; // Optional for performance monitoring

// Create a React Root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App within React.StrictMode
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional: Log performance metrics (e.g., measure loading speed or TTFB)
reportWebVitals();
