import React from 'react';

function ScanResults({ scans = [], onRefresh, onRemoveSchedule, onHoldSchedule, onGenerateReport }) {
  // Early return for empty scans array
  if (scans.length === 0) {
    return <p className="text-gray-600">No scans found.</p>;
  }

  return (
    <div>
      <h2 className="text-xl font-semibold text-blue-700 mb-4">Scan Results</h2>
      <div className="space-y-4">
        {scans.map((scan, index) => (
          <div
            key={scan.scan_id || `${scan.target_url}-${scan.created_at || index}`}
            className="bg-gray-100 p-4 rounded-lg shadow mb-4 relative group"
          >
            <div className="flex justify-between items-start">
              {/* Scan Details */}
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-blue-700">
                  {scan.target_url}
                </h3>
                <p className="text-sm text-gray-600">
                  Created: {formatDate(scan.created_at)}
                </p>
                <p className={`text-sm ${getStatusColor(scan.status)}`}>
                  Status: {scan.status || 'Unknown'}
                </p>

                {/* Display Scheduling Info */}
                {scan.schedule_frequency && scan.schedule_frequency !== 'immediate' && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-600">
                      Frequency: {capitalizeFirstLetter(scan.schedule_frequency)}
                    </p>
                    <p className="text-sm text-gray-600">
                      Start Time: {formatDate(scan.start_time)}
                    </p>
                  </div>
                )}

                <div className="mt-2 flex gap-4">
                  <span className="bg-red-50 text-red-700 px-3 py-1 rounded-full text-sm">
                    High: {scan.findings?.high || 0}
                  </span>
                  <span className="bg-yellow-50 text-yellow-700 px-3 py-1 rounded-full text-sm">
                    Medium: {scan.findings?.medium || 0}
                  </span>
                  <span className="bg-blue-50 text-blue-700 px-3 py-1 rounded-full text-sm">
                    Low: {scan.findings?.low || 0}
                  </span>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col items-end">
                <button
                  onClick={() => onRefresh(scan.scan_id)}
                  className="mb-2 p-2 text-gray-500 hover:text-blue-600 rounded-full hover:bg-blue-100 transition-colors"
                  aria-label={`Refresh scan for ${scan.target_url}`}
                  title="Refresh scan"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Generate/View Reports */}
                <div className="mt-2">
                  {scan.pdf_url && scan.csv_url ? (
                    <>
                      <a
                        href={scan.pdf_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline block"
                      >
                        View PDF
                      </a>
                      <a
                        href={scan.csv_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline block"
                      >
                        View CSV
                      </a>
                    </>
                  ) : (
                    <button
                      onClick={() => onGenerateReport(scan.scan_id)}
                      className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 transition-colors"
                      title="Generate reports"
                    >
                      Generate Reports
                    </button>
                  )}
                </div>

                {scan.schedule_frequency && scan.schedule_frequency !== 'immediate' && (
                  <>
                    <button
                      onClick={() => onHoldSchedule(scan.scan_id)}
                      className="mb-2 bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600 transition-colors"
                      title="Put scan on hold"
                    >
                      Put on Hold
                    </button>
                    <button
                      onClick={() => onRemoveSchedule(scan.scan_id)}
                      className="bg-red-600 text-white py-1 px-3 rounded hover:bg-red-700 transition-colors"
                      title="Remove schedule"
                    >
                      Remove Schedule
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  /**
   * Helper to format dates.
   * @param {string | undefined} dateStr - ISO date string
   * @returns {string} - Formatted date or fallback
   */
  function formatDate(dateStr) {
    if (!dateStr) return 'Unknown date';
    try {
      const date = new Date(dateStr);
      return isNaN(date.getTime()) ? 'Invalid date' : date.toLocaleString();
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  }

  /**
   * Helper to capitalize the first letter of a string.
   * @param {string} str - The string to capitalize.
   * @returns {string} - The capitalized string.
   */
  function capitalizeFirstLetter(str) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Determine the color class for a scan's status.
   * @param {string} status - The status of the scan.
   * @returns {string} - Corresponding Tailwind CSS class for text color.
   */
  function getStatusColor(status) {
    switch (status?.toLowerCase()) {
      case 'completed':
        return 'text-green-600';
      case 'running':
        return 'text-blue-600';
      case 'failed':
        return 'text-red-600';
      case 'on_hold':
        return 'text-yellow-600';
      default:
        return 'text-gray-600';
    }
  }
}

export default ScanResults;
