import React, { useState } from 'react';
import scanApi from '../../api/scanApi'; // Ensure the correct API instance is imported

function ScanForm({ onScanCreated, previousTargets }) {
  const [target, setTarget] = useState('');
  const [scheduleFrequency, setScheduleFrequency] = useState('immediate'); // Default to "Immediate"
  const [startTime, setStartTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!target.trim()) {
      setError('Please select or enter a target.');
      return;
    }

    try {
      setLoading(true);
      setError('');

      // Prepare the payload
      const payload = {
        target_url: target,
      };

      if (scheduleFrequency !== 'immediate') {
        if (!startTime) {
          setError('Please select a start time for the scheduled scan.');
          return;
        }
        payload.schedule_frequency = scheduleFrequency;
        payload.start_time = startTime;
      }

      // API call to create a new scan
      const response = await scanApi.post('/scans', payload);
      const newScan = response.data;

      // Notify the parent component
      onScanCreated(newScan);

      // Clear the form
      setTarget('');
      setScheduleFrequency('immediate');
      setStartTime('');
    } catch (err) {
      console.error('Error creating scan:', err);
      setError('Failed to create the scan. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded shadow">
      <h2 className="text-xl font-bold mb-4">Create New Scan</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <label htmlFor="target" className="block text-gray-700 mb-2">
          Target
        </label>
        <select
          id="target"
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          className="w-full mb-4 p-2 border rounded"
        >
          <option value="">Select a previously scanned target...</option>
          {previousTargets.map((t, idx) => (
            <option key={idx} value={t}>
              {t}
            </option>
          ))}
        </select>
        <p className="text-gray-500 text-sm mb-2">Or enter a custom target:</p>
        <input
          type="text"
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          placeholder="Enter a custom target"
          className="w-full mb-4 p-2 border rounded"
        />

        <label htmlFor="scheduleFrequency" className="block text-gray-700 mb-2">
          Schedule Frequency
        </label>
        <select
          id="scheduleFrequency"
          value={scheduleFrequency}
          onChange={(e) => setScheduleFrequency(e.target.value)}
          className="w-full mb-4 p-2 border rounded"
        >
          <option value="immediate">Immediately, Not Recurring</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="yearly">Yearly</option>
        </select>

        {scheduleFrequency !== 'immediate' && (
          <>
            <label htmlFor="startTime" className="block text-gray-700 mb-2">
              Start Time
            </label>
            <input
              type="datetime-local"
              id="startTime"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className="w-full mb-4 p-2 border rounded"
            />
          </>
        )}

        <button
          type="submit"
          disabled={loading}
          className={`w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {loading ? 'Creating Scan...' : 'Create Scan'}
        </button>
      </form>
    </div>
  );
}

export default ScanForm;
